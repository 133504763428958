import { GetCookie } from "../../provider/common";
import "tui-grid/dist/tui-grid.min.css";
import "axios-progress-bar/dist/nprogress.css";

// 전역변수
const axios = require("axios").default;
const MASTER_URL = process.env.MASTER_URL;

export async function estimateFile(file_name, file_size, rfile_name) {
  const req = await axios.post(
    process.env.PANDA_API_URL + "/api/estimate/register_excel",
    {
      file_name: file_name,
      file_size: file_size,
      rfile_name: rfile_name,
    },
    { headers: { Authorization: "Bearer " + (await GetCookie("token")) } }
  );
  return req.data;
}

// 견적내역 중에 견적완료되고, 조회된것만 카운트가 줄어들도록 반영
export async function getEstimateDataCnt() {
  try {
    const URL = process.env.PANDA_API_URL + "/api/estimate/check";
    const res = await axios.get(URL, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${await GetCookie("token")}`,
      },
    });
    if (res.data.status == 200) {
      return res.data.total_cnt;
    } else {
      return 0;
    }
  } catch (e) {
    return 0;
  }
}

// 견적내역 데이터를 얻는 함수
export async function getEstimateData(page) {
  let URL = process.env.PANDA_API_URL + `/api/estimate/user`;
  if (page) {
    URL += `?page=${page}`;
  }
  const res = await axios.get(URL, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${await GetCookie("token")}`,
    },
  });

  return res.data.data;
}
